import React, { FormEvent, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import Country from "../../components/CountryDropDown";
import infosvg from "../../resource/img/info.svg";
import registerTick from "../../resource/img/register-tick.svg";
// import LoadingAnimation from "../../components/LoadingAnimation";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";
// import DoneIcon from "@mui/icons-material/Done";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { MdDone } from "react-icons/md";
import { toast } from "react-toastify";
import MUIModal from "../../components/MUIModal";
import { CreditFacilityModal } from "../../modals";
import { confirmOtp, registerUser, sendOtp } from "../../requests/auth";

export const Page1 = ({ data, pageControl }: { pageControl: any; data: any }) => {
//    const navigate = useNavigate();
   const { changePage } = pageControl;
   const { email, setEmail } = data;
   const [loading, setLoading] = useState(false);
   let commonDomainsToggle = false;
   let commonDomains = ["@gmail.", "@yahoo.", "@hotmail.", "@icloud.", "@outlook.", "@aol."];
   commonDomains.map((item) => {
      var idx = email.indexOf(item);
      if (idx > -1) {
         commonDomainsToggle = true;
      }
      return "";
   });
   let regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
   // let [showAnim, setShowAnim] = React.useState(true);

   const submitForm = async (e: any) => {
	e.preventDefault();
      setLoading(true);
      try {
         const res = await sendOtp({ email });
         if (res?.status === 200) {
            toast.success("OTP sent"); // success notification
            changePage(2);
         }
      } catch (e) {
         toast.error("Error sending otp");
         console.log(e);
      } finally {
         setLoading(false);
      }
   };

   return (
      <div className="page1new">
         <div className="heading">
            <h5>Let's get started</h5>
            <p className="small">
               Join over <span style={{ fontWeight: 500 }}>800 companies</span> doing business
               travel the right way
            </p>
         </div>
         <div className="form-section">
            <form onSubmit={submitForm}>
               <div className="form-item">
                  <div className="new-parent-label">
                     <div className="new-custom">
                        <label htmlFor="email">Please put in work email</label>
                        <Tooltip disableFocusListener arrow title="Only custom domains are allowed">
                           <img className="infosvg" src={infosvg} alt="" />
                        </Tooltip>
                        {/* <div className="left-custom"></div>
                                <div className="right-custom">
                                    <span className="custom">
                                        Only custom domains are allowed
                                    </span>
                                </div> */}
                     </div>
                  </div>
                  <input
                     type="email"
                     name="email"
                     id="email"
                     placeholder="yourname@yourdomain.com"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                  />
                  {commonDomainsToggle && <p className="prompt">Please put in a custom domain</p>}
               </div>
               <div className="button-wrapper">
                  <Button
                     type="submit"
                     className={`pry`}
                     disabled={!regexEmail.test(email) || commonDomainsToggle}
                     loading={loading}
                  >
                     Next
                  </Button>
               </div>
            </form>
         </div>
         <div className="bottom">
            <p>Already have an account?</p>
            <Link to="/login">Login instead</Link>
         </div>
         <div className="dots">
            <div className="current"></div>
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   );
};
export const Page2 = ({ pageControl, data }: { pageControl: any; data: any }) => {
   let { reducePage, changePage } = pageControl;
   let { email } = data;
   let [loading, setLoading] = React.useState(false);
   let [confirmation, setConfirmation] = React.useState("");

   const submitForm = async (e:FormEvent<HTMLFormElement>) => {
	e.preventDefault();
	setLoading(true);
	try {
	   const res = await confirmOtp({ email, otp: confirmation });
	   if (res?.status === 200) {
		  toast.success("OTP confirmed"); // success notification
		  changePage(3);
	   }
	} catch (e) {
	   toast.error("Error: Wrong OTP. Please try again");
	   console.log(e);
	} finally {
	   setLoading(false);
	}
 }

   return (
      <div className="page2new">
         <div className="heading">
            <span>
               We’ve sent a confirmation code to
               <span style={{ color: "#40798C" }}> {email}.</span>
               <br /> Input the code quick, code expires in 5 minutes.
            </span>{" "}
         </div>
         <div className="form-section">
            <form onSubmit={submitForm}>
               <div className="form-item">
                  <div className="info-wrapper">
                     <label htmlFor="confirmation-code">Confirmation Code</label>
                  </div>
                  <input
                     type="text"
                     name="confirmation-code"
                     id="confirmation-code"
                     value={confirmation}
                     onChange={(e) => {
                        setConfirmation(e.target.value);
                     }}
                  />
               </div>
               <div className="button-wrapper">
                  <div className="previous-wrapper">
                     <HiOutlineArrowLeft size={16} color="#C4C4C4" />
                     <p
                        onClick={(e) => {
                           reducePage(e);
                        }}
                     >
                        Previous
                     </p>
                  </div>

                  <Button
				  type="submit"
                     className="pry"
                   
                     disabled={confirmation.length < 6}
                     loading={loading}
                  >
                     Next
                  </Button>
               </div>
            </form>
         </div>
         <div className="bottom">
            <p></p>
            <p>
               Keep this open while you check your email. <br />
               Haven’t received our email? Try the spam folder or{" "}
               <span
                  onClick={async (e) => {
                     try {
                        const res = await sendOtp({ email });
                        if (res?.status === 200) {
                           toast.success("OTP resent"); // success notification
                        }
                     } catch (e) {
                        toast.error("Error resending otp");
                        console.log(e);
                     }
                  }}
                  className="hover"
               >
                  {" "}
                  resend it
               </span>
               .
            </p>
         </div>
         <div className="dots">
            <div className="current"></div>
            <div className="current"></div>
            <div></div>
            <div></div>
         </div>
      </div>
   );
};

export const Page3 = ({ pageControl, data }: { pageControl: any; data: any }) => {
   let { changePage, reducePage } = pageControl;
   let {
      firstName,
      setFirstName,
      lastName,
      setLastName,
      username,
      setUsername,
      phone,
      setPhone,
      password,
      setPassword,
      confirmPassword,
      setConfirmPassword,
   } = data;
   return (
      <div className="page3new">
         <div className="heading" style={{ padding: 0 }}>
            <h5>Tell us about yourself</h5>
            {/* <p>Tell us about yourself</p> */}
         </div>
         <div className="form-section">
            <form>
               <div className="form-rows">
                  <div className="form-row">
                     <div className="form-item">
                        <label htmlFor="first-name">First Name</label>
                        <input
                           type="text"
                           name="first-name"
                           id="first-name"
                           value={firstName}
                           onChange={(e) => {
                              setFirstName(e.target.value);
                           }}
                        />
                     </div>
                     <div className="form-item">
                        <label htmlFor="last-name">Last Name</label>
                        <input
                           type="text"
                           name="last-name"
                           id="last-name"
                           value={lastName}
                           onChange={(e) => {
                              setLastName(e.target.value);
                           }}
                        />
                     </div>
                  </div>
                  <div className="form-row">
                     <div className="form-item">
                        <label htmlFor="phone-number">Phone number</label>
                        <input
                           type="text"
                           name="phone-number"
                           id="phone-number"
                           value={phone}
                           onChange={(e) => {
                              setPhone(e.target.value);
                           }}
                        />
                     </div>
                     <div className="form-item">
                        <label htmlFor="username">Username</label>
                        <input
                           type="text"
                           name="username"
                           id="username"
                           value={username}
                           onChange={(e) => {
                              setUsername(e.target.value);
                           }}
                        />
                     </div>
                  </div>
                  <div className="form-row">
                     <div className="form-item">
                        <label htmlFor="password">Password</label>
                        <input
                           type="password"
                           name="password"
                           id="password"
                           value={password}
                           onChange={(e) => {
                              setPassword(e.target.value);
                           }}
                        />
                     </div>
                  </div>
                  <div className="form-row">
                     <div className="form-item">
                        <label htmlFor="confirm-password">Confirm your password</label>
                        <input
                           type="password"
                           name="confirm-password"
                           id="confirm-password"
                           value={confirmPassword}
                           onChange={(e) => {
                              setConfirmPassword(e.target.value);
                           }}
                        />
                     </div>
                  </div>
               </div>
               <div>
                  <p
                     className="forgot-link"
                     style={{
                        color: "#9C9393",
                     }}
                  >
                     By signing up, you agree to our
                     <Link
                        to="/#"
                        style={{
                           textDecoration: "none",
                           color: "#40798c",
                        }}
                        className="hover"
                     >
                        {" "}
                        Terms of Conditions
                     </Link>{" "}
                     and
                     <Link
                        to="/#"
                        style={{
                           textDecoration: "none",
                           color: "#40798c",
                        }}
                        className="hover"
                     >
                        {" "}
                        Privacy policy
                     </Link>
                     .
                  </p>
                  <p style={{ color: "red", fontSize: "80%" }}>
                     {confirmPassword.length >= 1 &&
                        password !== confirmPassword &&
                        "Passwords do not match"}
                  </p>
               </div>

               <div className="button-wrapper">
                  <div className="previous-wrapper">
                     <HiOutlineArrowLeft size={16} color="#C4C4C4" />
                     <p
                        onClick={(e) => {
                           reducePage(e);
                        }}
                     >
                        Previous
                     </p>
                  </div>

                  <Button
                     className="pry"
                     onClick={(e: any) => {
                        if (phone.length < 11) toast.error("Please enter a valid phone number");
                        else changePage(4);
                     }}
                     disabled={
                        firstName.length === 0 ||
                        lastName.length === 0 ||
                        phone.length === 0 ||
                        username.length === 0 ||
                        confirmPassword.length === 0 ||
                        password.length === 0 ||
                        confirmPassword !== password
                     }
                  >
                     Next
                  </Button>
               </div>
            </form>
         </div>
         {/* <div className="bottom"></div> */}
         <div className="dots">
            <div className="current"></div>
            <div className="current"></div>
            <div className="current"></div>
            <div></div>
         </div>
      </div>
   );
};

export const Page4 = ({ pageControl, data }: { pageControl: any; data: any }) => {
   let { changePage, reducePage } = pageControl;
   let {
      email,
      username,
      firstName,
      lastName,
      password,
      phone,
      confirmPassword,
      creditAmount,
    //   setCreditAmount,
      creditPeriod,
    //   setCreditPeriod,
      travelVolume,
    //   setTravelVolume,
      travelBudget,
    //   setTravelBudget,
      country,
      setCountry,
      role,
      setRole,
      companySize,
      setCompanySize,
      credit,
      setCredit,
      companyName,
      setCompanyName,
   } = data;
   // if (registerError) {
   // 	let data = registerError.response.data;
   // 	console.log(Object.keys(data));
   // 	console.log(data.length);
   // 	for (let index = 0; index < Object.keys(data).length; index++) {
   // 		console.log(index);
   // 		console.log(Object.keys(data)[index], data[Object.keys(data)[index]]);
   // 	}
   // }
//    let stringInputError = (a: any, setA: (g: any) => void) => {
//       console.log(a, isNaN(a));
//       if (!isNaN(a)) {
//          setA(a);
//       }
//    };
   let [loading, setLoading] = React.useState(false);
   const [searchParams] = useSearchParams();

const submitForm = async (e:FormEvent<HTMLFormElement>) => {
	e.preventDefault();
	// handleRegister(e);
	console.log({
	   username,
	   email,
	   first_name: firstName,
	   last_name: lastName,
	   password1: password,
	   password2: confirmPassword,
	   job_role: role,
	   phone_number: phone,
	   credit_required: credit,
	   company_name: companyName,
	   location: country,
	   company_size: companySize,
	   credit_amount: creditAmount,
	   credit_period: creditPeriod,
	   travel_volume: travelVolume,
	   annual_travel_budget: travelBudget,
	   ...(searchParams.get("ref")
		  ? { referral_code: searchParams.get("ref") }
		  : {}),
	});
	setLoading(true);
	try {
	   const res = await registerUser({
		  username,
		  email,
		  first_name: firstName,
		  last_name: lastName,
		  password1: password,
		  password2: confirmPassword,
		  job_role: role,
		  phone_number: phone,
		  credit_required: credit,
		  company_name: companyName,
		  location: country,
		  company_size: companySize,
		  credit_amount: creditAmount,
		  credit_period: creditPeriod,
		  travel_volume: travelVolume,
		  annual_travel_budget: travelBudget,
		  ...(searchParams.get("ref")
			 ? { referral_code: searchParams.get("ref") }
			 : {}),
	   });
	   if (res?.status === 201) {
		  toast.success("Congratulations! Your account has been created.");
		  changePage(6);
	   }
	} catch (e) {
	   console.log(e);
	} finally {
	   setLoading(false);
	}
 }
   

   return (
      <div className="page4new">
         <MUIModal
            open={credit}
            handleClose={() => setCredit(false)}
            // sticky={false}
         >
            <CreditFacilityModal data={data} />
         </MUIModal>
         <div className="heading" style={{ padding: 0 }}>
            <h5>Tell us about your company</h5>
         </div>
         <div className="form-section">
            <form onSubmit={submitForm}>
               <div className="form-rows">
                  <div className="form-row">
                     <div className="form-item">
                        <label htmlFor="company-name">Company's Name</label>
                        <input
                           type="text"
                           name="company-name"
                           id="company-name"
                           value={companyName}
                           onChange={(e) => {
                              setCompanyName(e.target.value);
                           }}
                        />
                     </div>
                  </div>
                  <div className="form-row">
                     <div className="form-item">
                        <label htmlFor="countries">Country</label>
                        <Country setCountry={setCountry} country={country} />
                     </div>
                     <div className="form-item">
                        <label htmlFor="role">Role</label>
                        <div className="select-wrapper">
                           <select
                              id="role"
                              name="role"
                              value={role}
                              onChange={(e) => {
                                 setRole(e.target.value);
                              }}
                           >
                              <option value="">Select Role</option>
                              <option value="Business Owner">Business owner</option>
                              <option value="Admin">Admin</option>
                              <option value="HR">HR</option>
                              <option value="Finance">Finance</option>
                              <option value="Procurement">Procurement</option>
                              <option value="Others">Others</option>
                           </select>
                        </div>
                     </div>
                  </div>

                  <div className="form-row">
                     <div className="form-item">
                        <label htmlFor="company-size">Company’s Size</label>
                        <div className="select-wrapper">
                           <select
                              id="company-size"
                              name="company-size"
                              value={companySize}
                              onChange={(e) => {
                                 setCompanySize(e.target.value);
                              }}
                           >
                              <option value="">Select Company Size</option>

                              <option value="10">0-10</option>
                              <option value="50">11-50</option>
                              <option value="100">51-100</option>
                              <option value="500">500 {">"}</option>
                           </select>
                        </div>
                     </div>
                  </div>

                  <div className="form-row">
                     <div className="form-item">
                        <label htmlFor="credit-facility">Would you require credit facility?</label>
                        <div className="select-wrapper">
                           <select
                              id="credit-facility"
                              name="credit-facility"
                              onChange={(e) => {
                                 setCredit(e.target.value === "true" ? true : false);
                                 console.log(credit);
                              }}
                           >
                              <option value={"false"}>...</option>

                              <option value={"true"}>Yes</option>

                              <option value={"false"}>No</option>
                           </select>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="button-wrapper">
                  <div className="previous-wrapper">
                     <HiOutlineArrowLeft size={16} color="#C4C4C4" />
                     <p
                        onClick={(e) => {
                           reducePage(e);
                        }}
                     >
                        Previous
                     </p>
                  </div>

                  <Button
                     className="pry"
                     loading={loading}
					 type="submit"
                     disabled={
                        companyName.length === 0 ||
                        country.length === 0 ||
                        companySize.length === 0 ||
                        role.length === 0
                     }
                  >
                     Finish
                  </Button>
               </div>
            </form>
            {/* <div className="bottom"></div> */}
         </div>
         {/* <div className="bottom"></div> */}
         <div className="dots">
            <div className="current"></div>
            <div className="current"></div>
            <div className="current"></div>
            <div className="current"></div>
         </div>
      </div>
   );
};

// export const Page5 = ({ data }: { data: any }) => {
//   let {
//     credit,
//     setCredit,
//     creditAmount,
//     setCreditAmount,
//     creditPeriod,
//     setCreditPeriod,
//     travelVolume,
//     setTravelVolume,
//     travelBudget,
//     setTravelBudget,
//   } = data;
//   return (
//     <div className="page5new">
//       <div className="popup">
//         <div className="popup-heading">
//           <p>Credit Facility</p>
//         </div>
//         <div className="popup-content">
//           <div className="form-section">
//             <form>
//               <div className="form-item">
//                 <h5>Credit Amount</h5>
//                 <div className="select-wrapper">
//                   <select
//                     id="creditAmount"
//                     name="creditAmount"
//                     value={creditAmount}
//                     onChange={(e) => {
//                       setCreditAmount(Number(e.target.value));
//                     }}
//                   >
//                     <option value={"1,000,000"}>1,000,000</option>
//                     <option value={"3,000,000"}>3,000,000</option>
//                     <option value={"5,000,000"}>5,000,000</option>
//                     <option value={"5,000,001"}>{">"}50,000,000</option>
//                   </select>
//                 </div>
//               </div>
//               <div className="form-item">
//                 <h5>Credit Period</h5>
//                 <div className="select-wrapper">
//                   <select
//                     id="creditPeriod"
//                     name="creditPeriod"
//                     value={creditPeriod}
//                     onChange={(e) => {
//                       setCreditPeriod(e.target.value);
//                     }}
//                   >
//                     <option value={"7"}>7 days</option>
//                     <option value={"14"}>14 days</option>
//                     <option value={"21"}>21 days</option>
//                   </select>
//                 </div>
//               </div>

//               <div className="form-item">
//                 <h5>Estimated travel monthly spend</h5>
//                 <input
//                   type="text"
//                   id="travelVolume"
//                   name="travelVolume"
//                   value={travelVolume}
//                   onChange={(e) => {
//                     setTravelVolume(Number(e.target.value));
//                   }}
//                 />
//               </div>
//               <div className="form-item">
//                 <h5>Estimated annual travel budget</h5>
//                 <input
//                   type="text"
//                   id="travelBudget"
//                   name="travelBudget"
//                   value={travelBudget}
//                   onChange={(e) => {
//                     setTravelBudget(Number(e.target.value));
//                   }}
//                 />
//               </div>

//               <div className="credit-button-wrapper">
//                 {credit.length > 0 && creditAmount.length > 0 && (
//                   <Button
//                     title="Add"
//                     className="pry"
//                     onClick={(e: any) => {
//                       setCredit(!credit);
//                     }}
//                   />
//                 )}
//                 {(credit.length === 0 || creditAmount.length === 0) && (
//                   <Button
//                     title="Add"
//                     className="pry disabled"
//                     onClick={(e: any) => {}}
//                   />
//                 )}

//                 <Button
//                   title="Cancel"
//                   className="sec"
//                   onClick={(e: any) => {
//                     setCredit(!credit);
//                   }}
//                 />
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export const Page6 = ({ pageControl }: { pageControl: any }) => {
   let [refer, setRefer] = React.useState(true);
   let { reducePage } = pageControl;
   let navigate = useNavigate();
   const [copied, setCopied] = useState(false);
   return (
      <div className="page6new-wrapper">
         <div className="page6new-final">
            <div className="top">
               <h5>Welcome to the future of Business Travel 🎉</h5>
               <p>
                  We are creating your account. An account manager will get in touch with you to
                  walk you through the platform shortly.
               </p>
            </div>
            <div className="refer">
               <h5>Refer a business</h5>
               <div className="refer-options">
                  <div className="top">
                     <p
                        className={refer ? "active" : "inactive"}
                        onClick={() => {
                           setRefer(true);
                        }}
                     >
                        Invite by Email
                     </p>
                     <p
                        className={!refer ? "active" : "inactive"}
                        onClick={() => {
                           setRefer(false);
                        }}
                     >
                        {" "}
                        Invite by Link
                     </p>
                  </div>
                  <div className="form-item">
                     {refer && <input className="input" placeholder="Enter email" />}
                     {!refer && (
                        <p className="input">
                           <span>https://new-kardinal.surge.sh</span>
                           <span
                              onClick={() => {
                                 navigator.clipboard.writeText("https://new-kardinal.surge.sh"); // this copies the link to clipboard

                                 setCopied(true);
                                 setTimeout(() => {
                                    setCopied(false);
                                 }, 2000);
                              }}
                           >
                              {copied ? <MdDone color="#07bc0c" /> : <ContentCopyIcon />}
                           </span>
                        </p>
                     )}

                     <div
                        className="button-wrapper"
                        style={{
                           visibility: refer ? "visible" : "hidden",
                        }}
                     >
                        <div className="previous-wrapper">
                           <HiOutlineArrowLeft size={16} color="#C4C4C4" />
                           <p
                              onClick={(e) => {
                                 reducePage(e);
                              }}
                           >
                              Previous
                           </p>
                        </div>
                        <Button
                           className="pry"
                           onClick={(e: any) => {
                              navigate("/login");
                           }}
                        >
                           Send
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export const Left = ({ content }: { content: any }) => {
   let LeftContent = [
      {},
      {
         heading: "Experience the new way of business trip",
         body: "One platform for all your bookings.",
      },
      {
         heading: "Insights on the go!",
         body: "Invoicing and reporting for when you need it.",
      },
      {
         heading: "Seamless payment",
         body: "Pay with card or get our personalized credit facility.",
      },
      {
         heading: "Travel support like never before",
         body: "Experience K-Care, your own travel assistant.",
      },
      {
         heading: "Travel support like never before",
         body: "Experience K-Care, your own travel assistant.",
      },
   ];
   let { heading, body } = LeftContent[content];

   return (
      <div className="left1">
         <h4>{heading}</h4>
         <div className="left-check">
            <img src={registerTick} alt="register-tick" />
            <p>{body}</p>
         </div>
      </div>
   );
};
