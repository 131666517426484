import { useEffect, useState } from "react";
import "react-alice-carousel/lib/scss/alice-carousel.scss";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import { MainAppLayout } from "./layouts";
// import Header from "./components/Header";
// import Home from "./view/Home";
import SpinLoader from "./components/SpinLoader";
import ProtectedRoute from "./helpers";
import {
	fetchAirportList,
	fetchCompanyData,
	fetchIntlAirportList,
	fetchTravellers,
	fetchUserData
} from "./requests";
import BookOnHold from "./view/BookOnHold";
import CustomLoader from "./view/CustomLoader";
import Dashboard from "./view/Dashboard";
import BookedSummaryPage from "./view/Flights/BookedSummaryPage";
import FlightConfirmationPage from "./view/Flights/FlightConfirmationPage";
import FlightPage from "./view/Flights/FlightPage";
import OnHoldSummaryPage from "./view/Flights/OnHoldSummaryPage";
import HotelPage from "./view/Hotels/HotelPage";
import InvoicingPage from "./view/InvoicingPage";
import SingleInvoice from "./view/InvoicingPage/Singlelnvoice";
import Login from "./view/Login";
import Register from "./view/Register";
import ReportingPage from "./view/Reporting";
import SettingsPage from "./view/SettingsPage";
import TravelPage from "./view/Travel";
import Trip from "./view/Trip";
import TripHistory from "./view/TripHistory";
import { useTripStore } from "./zustand/store";

//react-toastify...for notifications
import "react-modern-drawer/dist/index.css";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SummarySkeleton from "./components/SummarySkeleton";
import { countryListFromAPI } from "./helpers/countrylist";
import CarCompletePage from "./view/Cars/CarCompletePage";
import CarSuccessfulPage from "./view/Cars/CarSuccessfulPage";
import ConfirmedTicket from "./view/DownloadableTickets/confirmed";
import OnHoldTicket from "./view/DownloadableTickets/on-hold";
import Events from "./view/Events";
import BookingCompletePage from "./view/Flights/BookingCompletePage";
import EditFauxPage from "./view/Flights/EditFauxPage";

// const contextClass = {
// 	success: "bg-blue-600",
// 	error: "bg-red-600",
// 	info: "bg-gray-600",
// 	warning: "bg-orange-400",
// 	default: "bg-indigo-600",
// 	dark: "bg-white-600 font-gray-300",
// };

function App() {
	// zustand global states and setters
	// const userData = useTripStore((state: any) => state.userData);
	const newLogin = useTripStore((state: any) => state.newLogin);
	const updateUserData = useTripStore((state: any) => state.updateUserData);
	const updateCompanyData = useTripStore(
		(state: any) => state.updateCompanyData
	);
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);
	const setCountryList = useTripStore((state: any) => state.setCountryList);
	const setMyTravellers = useTripStore((state: any) => state.setMyTravellers);
	const setDropDownLoading = useTripStore(
		(state: any) => state.setDropDownLoading
	);
	const setGlobalAirports = useTripStore(
		(state: any) => state.setGlobalAirports
	);
	const globalAirports = useTripStore((state: any) => state.globalAirports);
	const setLocalAirports = useTripStore((state: any) => state.setLocalAirports);

	const location = useLocation();

	// react local state
	const [userLoading, setUserLoading] = useState(false);

	useEffect(() => {
		const fetchFunc = async () => {
			setUserLoading(true);
			try {
				const userRes = await fetchUserData();
				console.log("user", userRes);
				updateUserData(userRes?.data);

				const companyRes = await fetchCompanyData();
				console.log("companyRes", companyRes);
				updateCompanyData(companyRes?.data?.data);
			} catch (e) {
				console.log(e);
			} finally {
				setUserLoading(false);
			}
		};

		fetchFunc();
		console.log("location", location);
		// eslint-disable-next-line
	}, [newLogin]);

	useEffect(() => {
		// fetch international airports
		const fetchAirports = async () => {
			setDropDownLoading({ intlAirports: true });
			try {
				const res2 = await fetchIntlAirportList();

				setGlobalAirports(res2.data);

				console.log("globalAirports", globalAirports);
				console.log(
					"emptyAirports",
					res2.data?.filter((item: any) => item?.city === "")
				);
			} catch (e) {
				console.log(e);
			} finally {
				setDropDownLoading({ intlAirports: false });
			}
		};
		fetchAirports();
			// eslint-disable-next-line
	}, []);

	useEffect(() => {
		// fetch local airports
		const fetchAirports = async () => {
			setDropDownLoading({ localAirports: true });
			try {
				const res1 = await fetchAirportList();
				console.log("fetchAirportList", res1);

				setLocalAirports(
					res1.data?.filter((item: any) => item.country === "Nigeria")
				);
			} catch (e) {
				console.log(e);
			} finally {
				setDropDownLoading({ localAirports: false });
			}
		};
		fetchAirports();
			// eslint-disable-next-line
	}, []);

	//populate country list - src: erstcountries.com
	const newList = countryListFromAPI?.sort(
		(a: any, b: any) => a?.name?.common - b?.name?.common
	);
	newList?.unshift(
		newList?.splice(
			newList?.findIndex((item: any) => item?.cca2 === "NG"),
			1
		)[0]
	);
	setCountryList(newList);

	//fetch travellers
	useEffect(() => {
		const fetchFunc = async () => {
			try {
				// const res = await fetchCountryList();
				// console.log("countries", res);
				// const newList = res?.data?.sort(
				// 	(a: any, b: any) => a?.name?.common > b?.name?.common
				// );
				// newList?.unshift(
				// 	newList?.splice(
				// 		newList?.findIndex((item: any) => item?.cca2 === "NG"),
				// 		1
				// 	)[0]
				// );
				// setCountryList(newList);

				const travellerRes = await fetchTravellers();
				console.log("travellerRes", travellerRes);
				setMyTravellers(travellerRes?.data?.data);
			} catch (e) {
				console.log(e);
			}
		};

		fetchFunc();
			// eslint-disable-next-line
	}, [newLogin]);

	useEffect(() => {
		//reset zustand
		if (!location.pathname.startsWith("/trips"))
			updateBigFlightData({
				search: "",
				selectedTraveller: [],
				departure: "",
				destination: "",
				departure_date: "",
				return_date: null,
				cabin: "economy",
				showTripType: "Roundtrip",
				selectedDeparture: undefined,
				selectedArrival: undefined,
				flightSelected: false,
				bookingConfirmed: false,
				paymentConfirmed: false,
				departureCity: [],
				arrivalCity: [],
				flightReference: "",
				route: "domestic",
				session_id: "",
				session_id2: "",
				searchData: {},
				arrivalData: {},
				exchange_rate: 0,
				multiCity: [
					{
						departure: "",
						destination: "",
						departure_date: "",
					},
					{
						departure: "",
						destination: "",
						departure_date: "",
					},
				],
				selectedMultiCityFlights: [],
				multiSearchData: [],
			});
				// eslint-disable-next-line
	}, [location.pathname]);

	return userLoading ? (
		<SpinLoader height="100vh" />
	) : (
		<div className="App">
			<Routes>
				<Route path="/" element={<MainAppLayout />}>
					<Route
						index
						element={
							<ProtectedRoute>
								<Dashboard />
							</ProtectedRoute>
						}
					/>
					<Route path="onboarding" element={<Register />} />
					<Route path="login" element={<Login />} />
					<Route path="test-hotel" element={<HotelPage />} />
					<Route path="trip-history" element={<TripHistory />} />
					{/* <Route path="old-trip-history" element={<OldTripHistory />} /> */}
					<Route path="settings" element={<SettingsPage />} />
					<Route path="invoicing" element={<InvoicingPage />} />
					<Route path="events" element={<Events />} />
					<Route path="invoicing/:invoice_id" element={<SingleInvoice />} />
					<Route
						path="test-loader"
						element={
							<CustomLoader
								info={{
									locations: "Lagos (LOS)  -  Dubai (DXB)",
									dates: "Thu, 19 May - Wed, 25 May",
									other: "    1 adult, Premium",
								}}
							/>
						}
					/>
					<Route
						path="reporting"
						element={
							<ProtectedRoute>
								<ReportingPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="dashboard"
						element={
							<ProtectedRoute>
								<Dashboard />
							</ProtectedRoute>
						}
					/>
					<Route
						path="book-on-hold"
						element={
							<ProtectedRoute>
								<BookOnHold />
							</ProtectedRoute>
						}
					/>
					<Route
						path="trips"
						element={
							<ProtectedRoute>
								<TravelPage />
							</ProtectedRoute>
						}
					> 
						<Route index element={<Trip />} />
						<Route path="edit-loading" element={<EditFauxPage />} />
						<Route
							path="flight-results"
							element={
								Object.keys(bigFlightData.searchData).length === 0 &&
								Object.keys(bigFlightData.multiSearchData[0] ?? {}).length ===
									0 ? (
									<Navigate replace to="/trips" />
								) : (
									<FlightPage />
								)
							}
						/>
						<Route
							path="flight-confirmation"
							element={<FlightConfirmationPage />}
						/>
						<Route path="on-hold-summary" element={<OnHoldSummaryPage />} />
						<Route path="booked-summary" element={<BookedSummaryPage />} />
						<Route path="booking-complete" element={<BookingCompletePage />} />
						<Route path="car-successful" element={<CarSuccessfulPage />} />
						<Route path="car-complete" element={<CarCompletePage />} />
					</Route>
					<Route path="/skel" element={<SummarySkeleton />} />
					<Route
						path="flight"
						element={
							<ProtectedRoute>
								<FlightPage />
							</ProtectedRoute>
						}
					/>

					<Route path="*" element={<>No route..</>} />
				</Route>
				<Route path="ticket/on-hold/:kardinal_ref" element={<OnHoldTicket />} />
				<Route
					path="ticket/confirmed/:kardinal_ref"
					element={<ConfirmedTicket />}
				/>
			</Routes>
			<ToastContainer
				position="bottom-center"
				// autoClose={false}
				autoClose={5000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover={true}
				transition={Zoom}
			/>
		</div>
	);
}

export default App;

// const ClearNavigate = ({ path }: { path: string }) => {
// 	const updateBigFlightData = useTripStore(
// 		(state: any) => state.updateBigFlightData
// 	);

// 	useEffect(() => {
// 		updateBigFlightData({
// 			search: "",
// 			selectedTraveller: [],
// 			departure: "",
// 			destination: "",
// 			departure_date: "",
// 			return_date: null,
// 			cabin: "economy",
// 			showTripType: "Roundtrip",
// 			selectedDeparture: undefined,
// 			selectedArrival: undefined,
// 			flightSelected: false,
// 			bookingConfirmed: false,
// 			paymentConfirmed: false,
// 			departureCity: [],
// 			arrivalCity: [],
// 			flightReference: "",
// 			route: "domestic",
// 			session_id: "",
// 			session_id2: "",
// 			searchData: {},
// 			arrivalData: {},
// 			exchange_rate: 0,
// 			multiCity: [
// 				{
// 					departure: "",
// 					destination: "",
// 					departure_date: "",
// 				},
// 				{
// 					departure: "",
// 					destination: "",
// 					departure_date: "",
// 				},
// 			],
// 			selectedMultiCityFlights: [],
// 			multiSearchData: [],
// 		});
// 	}, []);
// 	return <Navigate replace to={path} />;
// };
