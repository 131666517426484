import create from "zustand";
// interface Travellers {
// 	id: number;
// 	first_name: string;
// 	last_name: string;
// 	email: string;
// }

export const useTripStore = create((set: any) => ({
	newLogin: 0,
	updateNewLogin: () => set((state: any) => ({ newLogin: state.newLogin + 1 })),

	userData: {},
	updateUserData: (values: any) =>
		set((state: any) => ({ userData: { ...state.userData, ...values } })),

	companyData: {},
	updateCompanyData: (values: any) =>
		set((state: any) => ({ companyData: { ...state.companyData, ...values } })),

	localAirports: [],
	setLocalAirports: (values: any) =>
		set((state: any) => ({ localAirports: values })),

	myTravellers: [],
	setMyTravellers: (values: any) =>
		set((state: any) => ({ myTravellers: values })),

	dropDownLoading: {
		intlAirports: false,
		localAirports: false,
		carLocations: false,
		carList: false,
	},
	setDropDownLoading: (values: any) =>
		set((state: any) => ({
			dropDownLoading: { ...state.dropDownLoading, ...values },
		})),

	globalAirports: [],
	setGlobalAirports: (values: any) =>
		set((state: any) => ({ globalAirports: values })),

	carLocations: [],
	setCarLocations: (values: any) =>
		set((state: any) => ({ carLocations: values })),

	carList: [],
	setCarList: (values: any) => set((state: any) => ({ carList: values })),

	countryList: [],
	setCountryList: (values: any) =>
		set((state: any) => ({ countryList: values })),

	searchLoading: false,
	setSearchLoading: (value: boolean) =>
		set((state: any) => ({ searchLoading: value })),

	arrivalLoading: false,
	setArrivalLoading: (value: boolean) =>
		set((state: any) => ({ arrivalLoading: value })),

	transitionLoading: false,
	setTransitionLoading: (value: boolean) =>
		set((state: any) => ({ transitionLoading: value })),

	// The data you pass to the flight pages is all here
	bigFlightData: {
		search: "",
		selectedTraveller: [],
		departure: "",
		destination: "",
		departure_date: "",
		return_date: null,
		cabin: "economy",
		showTripType: "Roundtrip",
		multiCity: [
			{
				departure: "",
				destination: "",
				departure_date: "",
			},
			{
				departure: "",
				destination: "",
				departure_date: "",
			},
		],
		selectedDeparture: undefined,
		selectedArrival: undefined,
		selectedMultiCityFlights: [],
		flightSelected: false,
		bookingConfirmed: false,
		paymentConfirmed: false,
		departureCity: [],
		arrivalCity: [],
		flightReference: "",
		route: "domestic",
		session_id: "",
		session_id2: "",
		searchData: {},
		multiSearchData: [],
		arrivalData: {},
		exchange_rate: 0,
		booking_deadline: "",
	},
	updateBigFlightData: (values: any) =>
		set((state: any) => ({
			bigFlightData: { ...state.bigFlightData, ...values },
		})),

	// final approach to editing this shit. If this one doesn't work eh, I'm going to do mechanic
	editFlightData: {
		search: "",
		selectedTraveller: [],
		departure: "",
		destination: "",
		departure_date: "",
		return_date: null,
		cabin: "economy",
		showTripType: "Roundtrip",
		multiCity: [
			{
				departure: "",
				destination: "",
				departure_date: "",
			},
			{
				departure: "",
				destination: "",
				departure_date: "",
			},
		],
		selectedDeparture: undefined,
		selectedArrival: undefined,
		selectedMultiCityFlights: [],
		flightSelected: false,
		bookingConfirmed: false,
		paymentConfirmed: false,
		departureCity: [],
		arrivalCity: [],
		flightReference: "",
		route: "domestic",
		session_id: "",
		session_id2: "",
		searchData: {},
		multiSearchData: [],
		arrivalData: {},
		exchange_rate: 0,
		booking_deadline: "",
	},
	updateEditFlightData: (values: any) =>
		set((state: any) => ({
			editFlightData: { ...state.editFlightData, ...values },
		})),

	bigCarData: {
		selectedTraveller: [],
		service_type: "FULLDAY",
		carType: "",
		date: "",
		ISODate: "",
		time: "",
		pickUp: "",
		dropOff: "",
		bookingConfirmed: false,
		searchResults: [],
		kardinal_reference: "",
	},
	updateBigCarData: (values: any) =>
		set((state: any) => ({
			bigCarData: { ...state.bigCarData, ...values },
		})),
}));
