import React, { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import { confirmPasswordResetToken, requestPasswordResetToken, userLogin } from "../../requests/auth";
import registerTick from "../../resource/img/register-tick.svg";
import { useTripStore } from "../../zustand/store";

export const SignIn = ({ setPage }: { setPage: any }) => {
	let [username, setUsername] = React.useState("");
	let [password, setPassword] = React.useState("");
	let [loading, setLoading] = React.useState(false);
	const navigate = useNavigate();

	const updateNewLogin = useTripStore((state: any) => state.updateNewLogin);


	const LoginSubmit = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		try {
			const res = await userLogin({
				username,
				password,
			});
			if (res?.status === 200) {
				toast.success("Login successful."); // success notification
				navigate("/dashboard");
				updateNewLogin();
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}

	return (
		<div className="sign-innew">
			<div className="heading" style={{ padding: 0 }}>
				<h5>Login to your account</h5>
			</div>
			<div className="form-section">
				<form onSubmit={LoginSubmit}>
					<div className="form-item">
						<label htmlFor="username">Username</label>
						<input
							type="text"
							name="username"
							id="username"
							value={username}
							onChange={(e) => {
								setUsername(e.target.value);
							}}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="password">Password</label>
						<input
							type="password"
							name="password"
							id="password"
							value={password}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
						/>
					</div>
					<div className="button-wrapper">
						<div className="rem-me">
							<input name="rem-me" id="rem-me" type="checkbox" />
							<label htmlFor="rem-me">Remember me</label>
						</div>

						<Button
						type="submit"
							className={`pry`}
							// onClick={LoginSubmit}
							loading={loading}
							disabled={username.length < 3 || password.length < 6}
						>
							Login
						</Button>

						<p
						style={{width: "fit-content"}}
							className="forgot-link"
							onClick={() => {
								setPage(2);
							}}
						>
							Forgot your password?
						</p>
						{/* <div
							style={{
								width: "28px",
								height: "28px",
								border: "1.23077px solid #C4C4C4",
								borderRadius: "50%",
								display: "grid",
								placeItems: "center",
							}}
						>
							<FaLinkedinIn color="#c4c4c4" size={15} />
						</div> */}
					</div>
					{/* <div className="spinner-container">
            <svg className="spinner" role="alert" aria-live="assertive">
              <circle cx="30" cy="30" r="10" className="circle" />
            </svg>
          </div> */}
				</form>
			</div>
			<div className="bottom">
				<p>Don’t have an account?</p>
				<Link to="/onboarding">Sign up</Link>
			</div>
		</div>
	);
};
export const ForgotPassword = ({
	data,
	// handleForgot,
	setPage,
}: {
	data: any;
	handleForgot: any;
	setPage: any;
}) => {
	let { email, setEmail } = data;
	const [loading, setLoading] = useState(false);

	const submitForgotPassword =async (e:FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		try {
			const res = await requestPasswordResetToken({ email });
			if (res?.status === 200) {
				toast.success("Token sent. Please check your mail."); // success notification
				setPage(3);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}
	return (
		<div className="forgot-password-wrapper">
			<div className="forgot-password">
				<div className="heading">
					<h5>Forgot your password?</h5>
					<p>
						No worries. just enter your registered email and we will send a
						token to reset it.
					</p>
				</div>
				<div>
					<div className="form-section">
						<form onSubmit={submitForgotPassword}>
							<div className="form-item">
								<input
									type="email"
									value={email}
									placeholder="Enter your registered email"
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									aria-label="Enter your registered email"
								/>
							</div>
							<div className="button-wrapper">
								<Button
									className={`pry ${email.length < 5 ? "disabled" : ""}`}
									type="submit"
									loading={loading}
									disabled={email.length < 5}
								>
									Send reset token
								</Button>
							</div>
						</form>
					</div>
					<p className="check-spam">
						You may also need to check your spam folder .
					</p>
				</div>
				<div className="bottom">
					<p
						className="blue-link"
						onClick={() => {
							setPage(1);
						}}
					>
						Sign in
					</p>
				</div>
			</div>
		</div>
	);
};
export const Confirmation = ({
	data,
	setPage,
}: {
	data: any;
	setPage: any;
}) => {
	let { email, confirmationToken, setConfirmationToken } = data;
	// let [confirmation, setConfirmation] = React.useState<string>("");
	// const [loading, setLoading] = useState(false);
	return (
		<div className="confirmation">
			<div className="heading">
				<p>
					We’ve sent a confirmation code to <span>{email}</span>
				</p>
				<p>Input the code quick, code expires in 5 minutes.</p>
			</div>
			<div className="form-section">
				<form>
					<div className="form-item email">
						<label htmlFor="email">Email</label>
						<input
							type="email"
							name="email"
							id="email"
							value={email}
							placeholder={email}
							disabled={true}
							// onChange={(e) => {
							//   setEmail(e.target.value);
							// }}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="confirmation-code">Confirmation code</label>
						<input
							type="text"
							name="confirmation-code"
							id="confirmation-code"
							value={confirmationToken}
							onChange={(e) => {
								setConfirmationToken(e.target.value);
							}}
							placeholder="9638389"
						/>
					</div>

					<div className="last-section">
						<div className="button-wrapper" style={{ width: "100%" }}>
							<Button
								className="pry"
								onClick={() => {
									setPage(4);
								}}
								disabled={confirmationToken.length === 0}
							>
								Set new password
							</Button>
						</div>
					</div>
				</form>
			</div>
			<div className="bottom" style={{ paddingTop: "10%" }}>
				<p style={{ color: "#9C9393", paddingTop: "10px" }}>
					You may also need to check your spam folder for the email.
				</p>
				<p className="blue-link" onClick={() => setPage(1)}>
					Sign in
				</p>
			</div>
		</div>
	);
};
export const NewPassword = ({
	data,
	// handleConfirmPasswordReset,
	setPage,
}: {
	data: any;
	handleConfirmPasswordReset: any;
	setPage: any;
}) => {
	let {
		password,
		setPassword,
		confirmPassword,
		setConfirmPassword,
		confirmationToken,
	} = data;
	const [loading, setLoading] = useState(false);

	const submitNewPassword =async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// 	handleConfirmPasswordReset(e);
		setLoading(true);
		try {
			const res = await confirmPasswordResetToken({
				password,
				token: confirmationToken,
			});
			if (res?.status === 200) {
				toast.success("Password changed"); // success notification
				setPage(1);
			}
		} catch (e: any) {
			console.log(e);
			console.log(e?.response?.data?.detail);
			if (e?.response?.data?.detail === "Not found.") {
				setPage(3);
			}
		} finally {
			setLoading(false);
		}
	}
	return (
		<div className="new-password" style={{ display: "block" }}>
			<div className="heading" style={{ paddingTop: "15%" }}>
				<h5>Set a new password</h5>
				{/* <p>
          No worries. just enter your registered email and we will send a link
          to reset it.
        </p> */}
			</div>
			<div className="form-section" style={{ height: "50%" }}>
				<form onSubmit={submitNewPassword}>
					<div className="form-item">
						<label htmlFor="password">New password</label>
						<input
							type="password"
							name="password"
							id="password"
							value={password}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="confirm-password">Confirm new password</label>
						<input
							type="password"
							name="confirm-password"
							id="confirm-password"
							value={confirmPassword}
							onChange={(e) => {
								setConfirmPassword(e.target.value);
							}}
						/>
					</div>

					<div className="last-section">
						<div className="button-wrapper" style={{ width: "100%" }}>
							<Button
								className={`pry ${
									password.length === 0 ||
									confirmPassword.length === 0 ||
									confirmPassword !== password
										? "disabled"
										: ""
								}`}
								loading={loading}
								disabled={
									password.length === 0 ||
									confirmPassword.length === 0 ||
									confirmPassword !== password
								}
							type="submit"
							>
								Change Password
							</Button>
						</div>
					</div>
				</form>
			</div>
			<div className="bottom" style={{ height: "5%" }}></div>
		</div>
	);
};
export const Left = () => {
	return (
		<div className="left1">
			<h4>Welcome back!</h4>
			<div className="left-check">
				<img src={registerTick} alt="register-tick" />
				<p>The real action happens in person. Book a trip.</p>
			</div>
		</div>
	);
};
