import { Dispatch, SetStateAction, useRef, useState } from "react";
import "./style.scss";
// import { AiOutlinePlus } from "react-icons/ai";
import { IoAddOutline, IoChevronDown } from "react-icons/io5";
// import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
import { FiArrowUpRight, FiCheck } from "react-icons/fi";
import Button from "../../../components/Button";
import PlainTable from "../../../components/PlainTable";
// import UserAvatar from "../../../components/UserAvatar";
import {
	ArrivalIcon,
	BaggageIcon,
	DepartureIcon,
	DiamondIcon,
} from "../../../components/pieces/svgs";
// import LoadingAnimation from "../../../components/LoadingAnimation";
import { TbArrowsRightLeft } from "react-icons/tb";
import MUIModal from "../../../components/MUIModal";
import ConfirmationLayout from "../../../layouts/ConfirmationLayout";
import {
	FlightInfoModal,
	LoadingSuccessModal,
	NewGroupModal,
	// FareBreakdownModal,
	// ShareModal,
	PaymentMethodModal,
	SelectTravellerModal,
	ShareTripDetailsModal,
} from "../../../modals";
import { useTripStore } from "../../../zustand/store";
// import useAxios from "axios-hooks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DateTime } from "ts-luxon";
import {
	capitalizeFirstLetter,
	generateItenary,
	generateLayoverArray,
} from "../../../helpers/helperFuncs";
import {
	bookFlight, bookIntlFlight,
} from "../../../requests";
import { PassengerBlock, PriceBreakdownBlock } from "../confirmationComponents";
// import { HiOutlineCheckCircle } from "react-icons/hi";
import CategoryTag from "../../../components/CategoryTag";
import LoadingModalsDebacle from "../../../components/LoadingModalsDebacle";
import { EncryptionIcon } from "../../../components/pieces/svgs";
import PolicyFlag from "../../../components/PolicyFlag";
import SummarySkeleton from "../../../components/SummarySkeleton";
import { redoFlightSearch } from "../../../helpers/searchFlightFlow";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

const FlightConfirmationPage = (props: any) => {
	//zustand states
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);
	const globalAirports = useTripStore((state: any) => state.globalAirports);

	// react local state
	const [openPayment, setOpenPayment] = useState<boolean>(false);
	const [bookingLoading, setBookingLoading] = useState<boolean>(false);
	const [openShare, setOpenShare] = useState<boolean>(false);
	const [tempSelected, setTempSelected] = useState<any[]>(
		bigFlightData.selectedTraveller
	);
	const [openAppendPassengers, setOpenAppendPassengers] =
		useState<boolean>(false);
	const [reference, setReference] = useState("");
	const [paymentMethod, setPaymentMethod] = useState<null | string>(null);
	const [successLoading, setSuccessLoading] = useState<boolean>(false);
	const [restartSearchLoading, setRestartSearchLoading] =
		useState<boolean>(false);
	const [successFunc, setSuccessFunc] = useState<() => void>(
		() => () => console.log("init")
	);

	const navigate = useNavigate();

	const isRoundTripAndIntl =
		bigFlightData?.showTripType === "Roundtrip" &&
		bigFlightData?.route === "international";

	const isMultiCityAndLocal =
		bigFlightData?.showTripType === "Multicity" &&
		bigFlightData?.route === "domestic";

	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	let fareCost =
		bigFlightData?.selectedDeparture?.amount +
		(bigFlightData?.selectedArrival === undefined
			? 0
			: bigFlightData?.selectedArrival?.amount);
	let tax =
		bigFlightData?.selectedDeparture?.tax === undefined
			? 0
			: bigFlightData?.selectedDeparture?.tax +
			  (bigFlightData?.selectedArrival === undefined
					? 0
					: bigFlightData?.selectedArrival?.tax);
	// let bookingFee = 0;

	if (isRoundTripAndIntl || isMulticityAndIntl) {
		fareCost = bigFlightData.selectedDeparture?.amount;
		tax = bigFlightData.selectedDeparture.tax;
	}
	if (isMultiCityAndLocal) {
		fareCost = bigFlightData.selectedMultiCityFlights.reduce(
			(total: number, selectedObj: any) => total + selectedObj?.amount,
			0
		);
		tax = 0;
	}

	const amount = fareCost + tax;

	const confirmBooking = async (
		payment_method: string,
		latestReference: string = ""
	) => {
		setBookingLoading(true);
		let res1: any;

		setPaymentMethod(payment_method);

		try {
			if (
				// bigFlightData.showTripType === "Roundtrip" &&
				bigFlightData.route === "international"
			) {
				if (bigFlightData?.showTripType === "Multicity")
					res1 = await bookIntlFlight({
						travellers: bigFlightData.selectedTraveller.map(
							({ id }: { id: number }) => id
						),
						trip_type: "multicity",
						session_id: bigFlightData?.session_id,
						itemID: bigFlightData.selectedDeparture.id,
						type: bigFlightData?.route,
						payment_method: payment_method,
						...(payment_method === "pay_with_card" && {
							paystack_refID: latestReference,
						}),
						flight_cost: amount,
						noOfFlights: bigFlightData.multiCity?.length,
						flight:
							// bigFlightData.selectedDeparture?.outbound
							bigFlightData.multiCity
								?.flat()
								?.map((multiItem: any, ind: number) => ({
									origin: multiItem?.departure,
									origin_city: globalAirports.filter(
										(item: any) => item.iata_code === multiItem?.departure
									)[0]?.city,
									destination_: multiItem?.destination,
									destination_city: globalAirports.filter(
										(item: any) => item.iata_code === multiItem?.destination
									)[0]?.city,
								})),
						itenary: [generateItenary(bigFlightData)],
						layover: generateLayoverArray(bigFlightData),
					});
				else
					res1 = await bookIntlFlight({
						travellers: bigFlightData.selectedTraveller.map(
							({ id }: { id: number }) => id
						),
						trip_type:
							bigFlightData.showTripType === "Roundtrip" ? "return" : "oneway",
						session_id: bigFlightData?.session_id,

						itemID: bigFlightData.selectedDeparture.id,
						type: bigFlightData?.route,
						payment_method: payment_method,
						...(payment_method === "pay_with_card" && {
							paystack_refID: latestReference,
						}),
						flight_cost: amount,
						flight: {
							origin: bigFlightData.departure,
							origin_city: bigFlightData.departureCity[0]?.city,
							destination_: bigFlightData.destination,
							destination_city: bigFlightData.arrivalCity[0]?.city,
							departure_date: bigFlightData.departure_date,
							...(bigFlightData.showTripType === "Roundtrip" && {
								return_date: bigFlightData.return_date,
							}),
						},
						itenary: [generateItenary(bigFlightData)],
						layover: generateLayoverArray(bigFlightData),
					});
			} else {
				if (bigFlightData?.showTripType === "Multicity")
					res1 = await bookFlight({
						travellers: bigFlightData.selectedTraveller.map(
							({ id }: { id: number }) => id
						),
						trip_type: "multicity",
						session_id: bigFlightData?.session_id,
						type: bigFlightData?.route,
						payment_method: payment_method,
						...(payment_method === "pay_with_card" && {
							paystack_refID: latestReference,
						}),
						flight: bigFlightData.selectedMultiCityFlights?.map(
							(flightItem: any, ind: number) => ({
								flight_id: flightItem.id,
								origin: flightItem?.outbound[0]?.airport_from,
								origin_city: globalAirports.filter(
									(item: any) =>
										item.iata_code === flightItem?.outbound[0]?.airport_from
								)[0]?.city,
								origin_airport: globalAirports.filter(
									(item: any) =>
										item.iata_code === flightItem?.outbound[0]?.airport_from
								)[0]?.name,
								destination_: flightItem?.outbound[0]?.airport_to,
								destination_city: globalAirports.filter(
									(item: any) =>
										item.iata_code === flightItem?.outbound[0]?.airport_to
								)[0]?.city,
								destination_airport: globalAirports.filter(
									(item: any) =>
										item.iata_code === flightItem?.outbound[0]?.airport_to
								)[0]?.name,
								departure_date: bigFlightData.multiCity[ind]?.departure_date,
								airline_name: flightItem?.outbound[0]?.airline_details?.name,
								airline_logo: `https://image.tiqwa.com/airlines/${flightItem?.outbound[0]?.airline_details.code}.png`,
								flight_cost: flightItem.amount,
							})
						),
						total_flight_cost: amount,
						noOfFlights: bigFlightData.selectedMultiCityFlights?.length,
						itenary: [generateItenary(bigFlightData)],
					});
				else
					res1 = await bookFlight({
						travellers: bigFlightData.selectedTraveller.map(
							({ id }: { id: number }) => id
						),
						trip_type:
							bigFlightData.showTripType === "Roundtrip" ? "return" : "oneway",
						session_id: bigFlightData?.session_id,
						type: bigFlightData?.route,
						payment_method: payment_method,
						...(payment_method === "pay_with_card" && {
							paystack_refID: latestReference,
						}),
						departure: {
							flight_id: bigFlightData.selectedDeparture.id,
							origin: bigFlightData.departure,
							origin_city: bigFlightData.departureCity[0]?.city,
							origin_airport: bigFlightData.departureCity[0]?.name,
							destination_: bigFlightData.destination,
							destination_city: bigFlightData.arrivalCity[0]?.city,
							destination_airport: bigFlightData.arrivalCity[0]?.name,
							departure_date: bigFlightData.departure_date,
							airline_name:
								bigFlightData?.selectedDeparture?.outbound[0]?.airline_details
									?.name,
							airline_logo: `https://image.tiqwa.com/airlines/${bigFlightData.selectedDeparture?.outbound[0]?.airline_details?.code}.png`,
							flight_cost: bigFlightData.selectedDeparture.amount,
						},
						...(bigFlightData.showTripType === "Roundtrip" && {
							return: {
								flight_id: bigFlightData.selectedArrival.id,
								origin: bigFlightData.destination,
								origin_city: bigFlightData.arrivalCity[0]?.city,
								origin_airport: bigFlightData.arrivalCity[0]?.name,
								return_location: bigFlightData.departure,
								destination_city: bigFlightData.departureCity[0]?.city,
								destination_airport: bigFlightData.departureCity[0]?.name,
								airline_name:
									bigFlightData?.selectedArrival?.outbound[0]?.airline_details
										?.name,
								airline_logo: `https://image.tiqwa.com/airlines/${bigFlightData.selectedArrival?.outbound[0]?.airline_details.code}.png`,
								return_date: bigFlightData.return_date,
								flight_cost: bigFlightData.selectedArrival.amount,
							},
						}),
						flight_cost: amount,
						itenary: [generateItenary(bigFlightData)],
					});
			}
		} catch (e) {
			console.log(e);
			if (res1?.status >= 500) toast.error("Booking error");
		} finally {
			setBookingLoading(false);
			console.log("res1", res1);

			if (res1?.status === 200) {
				setSuccessFunc(() => () => {
					setTimeout(() => {
						updateBigFlightData({
							bookingConfirmed: true,
							flightReference:
								bigFlightData.route === "international"
									? res1?.data?.data?.kardinal_reference
									: bigFlightData.showTripType === "Multicity"
									? res1?.data?.data?.kardinal_reference
									: res1?.data?.message?.data?.kardinal_reference,
							booking_deadline:
								bigFlightData.route === "international"
									? res1?.data?.data?.booking_deadline
									: bigFlightData.showTripType === "Multicity"
									? res1?.data?.data?.flight_0?.booking_deadline
									: res1?.data?.message?.data?.departure?.booking_deadline,
							paymentConfirmed: payment_method !== "book_on_hold",
						});
						if (payment_method === "book_on_hold") {
							console.log(bigFlightData);
							navigate("/trips/on-hold-summary");
						} else {
							navigate("/trips/booked-summary");
						}
					}, 1000);
				});

				setSuccessLoading(true);
			} else if (res1?.status >= 500) {
				toast.error("Server error");
			} else {
				// toast.error("Something went wrong");
			}
		}
	};

	const writeUpList = [
		"This is a rare find! Proceed to pay now to secure your spot.",
		"A rare business class find! Proceed to pay now to secure your spot.",
		"You're in good company! 72% of business travellers like you chose this flight option for their trips.",
		"Join 85% of our users who booked this route within the last hour. Book now! 💼 ✈️",
		"You and 342 staff in similar companies booked this flight option.",
		"Don’t miss out! 15 travellers in similar role just booked this option. Secure your seats now.",
		"You picked the right choice! Confirm details of your trip before proceeding to pay.",
	];

	const updatePassengerList = async () => {
		const formerPassengers = [...tempSelected];
		updateBigFlightData({ selectedTraveller: tempSelected });

		if (tempSelected.length === bigFlightData?.selectedTraveller?.length) {
		} else {
			setRestartSearchLoading(true);
			try {
				const results = await redoFlightSearch(
					false,
					false,
					(par: boolean) => {},
					false,
					(par: boolean) => {},
					bigFlightData,
					updateBigFlightData,
					() => updateBigFlightData({ selectedTraveller: tempSelected })
				);
				console.log("results", results);
			} catch (err) {
				console.error(err);
				updateBigFlightData({ selectedTraveller: formerPassengers });
			} finally {
				setRestartSearchLoading(false);
			}
		}
	};

	if (!bigFlightData.flightSelected || bigFlightData.bookingConfirmed)
		return <SummarySkeleton />;
	else
		return (
			<>
				<div className="FlightConfirmationPage">
					<ConfirmationLayout>
						<div className="FlightConfirmationPage__wrapper">
							{/* <div
								className="back"
								onClick={() => {
									updateBigFlightData({
										selectedMultiCityFlights: [],
										selectedDeparture: undefined,
										selectedArrival: undefined,
										flightSelected: false,
									});
									navigate("/trips/flight-results");
								}}
							>
								<CgArrowLongLeft size={24} />
								<p>Search flights</p>
							</div> */}
							<div className="top-section">
								<div>
									<h3>Confirm your flight </h3>
									<div className="top-section__writeUp">
										<DiamondIcon />
										<span>
											{
												writeUpList[
													Math.floor(Math.random() * writeUpList.length)
												]
											}
										</span>
									</div>
								</div>
								{/* <button
									className="mobile-button"
									onClick={() => {
										setOpenPayment(true);
									}}
								>
									Proceed to pay
								</button> */}
							</div>
							<div className="travelIte__bottom">
								<div
									className="share"
									onClick={() => setOpenShare(true)}
									style={{ marginLeft: "auto", marginBottom: "0.75rem" }}
								>
									<span
										style={{
											textDecoration: "underline",
											textUnderlineOffset: "0.3rem",
										}}
									>
										Share trip details
									</span>{" "}
									<FiArrowUpRight />
								</div>
							</div>
							<div className="two-column">
								<PlainTable
									tableHead="Travel Itinerary"
									tableBody={<TravelIteneraryBody props={props} />}
								/>
							</div>
							<div className="passenger-section">
								<PassengerBlock
									disabled={false}
									titleRight={
										<div className="travelIte__bottom">
											{/* <div
												className="share"
												onClick={() => setOpenAppendPassengers(true)}
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													gap: "0.5rem",
												}}
											>
												<AiOutlinePlus />
												<span>Add passenger</span>
											</div> */}
										</div>
									}
								/>
							</div>
							<FlightCategorization />
							<div className="details-desktop">
								{isMultiCityAndLocal ? (
									<>
										{bigFlightData.selectedMultiCityFlights?.map(
											(flightObj: any, ind: number) => (
												<PlainTable
													tableHead={
														<DetailsTableHead
															type="Depart"
															flightObj={flightObj}
															multi={true}
															index={ind + 1}
														/>
													}
													tableBody={
														<DetailsTableBody
															type="Depart"
															flightObj={flightObj}
														/>
													}
													key={ind}
												/>
											)
										)}
									</>
								) : isMulticityAndIntl ? (
									bigFlightData.selectedDeparture.outbound.map(
										(selectedItem: any, ind: number) => (
											<PlainTable
												tableHead={
													<DetailsTableHead
														type="Depart"
														flightObj={{
															...bigFlightData.selectedDeparture,
															outbound:
																bigFlightData.selectedDeparture?.outbound[ind],
															outbound_stops:
																bigFlightData.selectedDeparture?.outbound_stops[
																	ind
																],
															total_duration:
																bigFlightData.selectedDeparture?.total_duration[
																	ind
																],
															total_outbound_duration:
																bigFlightData.selectedDeparture
																	?.total_outbound_duration[ind],
														}}
														multi={true}
														index={ind + 1}
													/>
												}
												tableBody={
													<DetailsTableBody
														type="Depart"
														flightObj={{
															...bigFlightData.selectedDeparture,
															outbound:
																bigFlightData.selectedDeparture?.outbound[ind],
															outbound_stops:
																bigFlightData.selectedDeparture?.outbound_stops[
																	ind
																],
															total_duration:
																bigFlightData.selectedDeparture?.total_duration[
																	ind
																],
															total_outbound_duration:
																bigFlightData.selectedDeparture
																	?.total_outbound_duration[ind],
														}}
													/>
												}
											/>
										)
									)
								) : (
									<>
										<PlainTable
											tableHead={
												<DetailsTableHead
													type="Depart"
													flightObj={bigFlightData.selectedDeparture}
												/>
											}
											tableBody={
												<DetailsTableBody
													type="Depart"
													flightObj={bigFlightData.selectedDeparture}
												/>
											}
										/>
										{bigFlightData.showTripType === "Roundtrip" && (
											<PlainTable
												tableHead={
													<DetailsTableHead
														type="Return"
														flightObj={bigFlightData.selectedArrival}
													/>
												}
												tableBody={
													<DetailsTableBody
														type="Return"
														flightObj={bigFlightData.selectedArrival}
													/>
												}
											/>
										)}
									</>
								)}
							</div>
						</div>

						<div>
							<div style={{ marginTop: "6.2rem" }}>
								<PriceBreakdownBlock tax={tax} fareCost={fareCost} />
								<div className="SecuredPayment__info">
									<p>Secured payment</p>
									<EncryptionIcon />
								</div>
								<div className="btnWrap">
									<Button
										className="pry"
										onClick={() => {
											// if (bigFlightData?.route === "international") {
											for (const traveller of bigFlightData?.selectedTraveller) {
												if (bigFlightData?.route === "international" && !traveller?.is_complete) {
													toast.error(
														"Click 'Edit' to complete passenger information"
													);
													return;
												}
											}
											setOpenPayment(true);
											// } else setOpenPayment(true);
										}}
									>
										Proceed to pay
									</Button>
								</div>
								<div className="flag">
									<PolicyFlag status={true} variant="long" />
								</div>
							</div>
						</div>
					</ConfirmationLayout>
				</div>
				<div className="FlightConfirmationPage-mobile">
					<div className="FlightConfirmationPage__wrapper">
						<div className="top-section">
							<div>
								<h3>Confirm your flight </h3>
								<p className="msg">
									You picked the right choice! Confirm details of your trip
									before proceeding to pay.
								</p>
							</div>
							<div
								className="mobile-button"
								onClick={() => {
									// if (bigFlightData?.route === "international") {
									for (const traveller of bigFlightData?.selectedTraveller) {
										if (bigFlightData?.route === "international" && !traveller?.is_complete) {
											toast.error(
												"Click 'Edit' to complete passenger information"
											);
											return;
										}
									}
									setOpenPayment(true);
									// } else setOpenPayment(true);
								}}
							>
								Proceed to pay
							</div>
						</div>
						<div className="passenger-section">
							<PassengerBlock
								disabled={false}
								titleRight={
									<div className="travelIte__bottom">
										<div className="share" onClick={() => setOpenShare(true)}>
											<span
												style={{
													textDecoration: "underline",
													textUnderlineOffset: "0.3rem",
												}}
											>
												Share trip details
											</span>{" "}
											<FiArrowUpRight />
										</div>
									</div>
								}
							/>
						</div>
						<div className="two-column">
							<PlainTable
								tableHead="Travel Itinerary"
								tableBody={<TravelIteneraryBody props={props} />}
							/>
							<PlainTable
								tableHead="Price breakdown"
								tableBody={<PriceBreakdownBody props={props} />}
							/>
							{isMultiCityAndLocal ? (
								<>
									{bigFlightData.selectedMultiCityFlights?.map(
										(flightObj: any, ind: number) => (
											<PlainTable
												tableHead={
													<MobileDetailsHead
														type="Depart"
														flightObj={flightObj}
														multi={true}
														index={ind + 1}
													/>
												}
												tableBody={
													<MobileDetailsBody
														type="Depart"
														flightObj={flightObj}
													/>
												}
												key={ind}
											/>
										)
									)}
								</>
							) : isMulticityAndIntl ? (
								bigFlightData.selectedDeparture.outbound.map(
									(selectedItem: any, ind: number) => (
										<PlainTable
											tableHead={
												<DetailsTableHead
													type="Depart"
													flightObj={{
														...bigFlightData.selectedDeparture,
														outbound:
															bigFlightData.selectedDeparture?.outbound[ind],
														outbound_stops:
															bigFlightData.selectedDeparture?.outbound_stops[
																ind
															],
														total_duration:
															bigFlightData.selectedDeparture?.total_duration[
																ind
															],
														total_outbound_duration:
															bigFlightData.selectedDeparture
																?.total_outbound_duration[ind],
													}}
													multi={true}
													index={ind + 1}
												/>
											}
											tableBody={
												<DetailsTableBody
													type="Depart"
													flightObj={{
														...bigFlightData.selectedDeparture,
														outbound:
															bigFlightData.selectedDeparture?.outbound[ind],
														outbound_stops:
															bigFlightData.selectedDeparture?.outbound_stops[
																ind
															],
														total_duration:
															bigFlightData.selectedDeparture?.total_duration[
																ind
															],
														total_outbound_duration:
															bigFlightData.selectedDeparture
																?.total_outbound_duration[ind],
													}}
												/>
											}
										/>
									)
								)
							) : (
								<>
									<PlainTable
										tableHead={
											<MobileDetailsHead
												type="Depart"
												flightObj={bigFlightData.selectedDeparture}
											/>
										}
										tableBody={
											<MobileDetailsBody
												type="Depart"
												flightObj={bigFlightData.selectedDeparture}
											/>
										}
									/>
									{bigFlightData.showTripType === "Roundtrip" && (
										<PlainTable
											tableHead={
												<MobileDetailsHead
													type="Return"
													flightObj={bigFlightData.selectedArrival}
												/>
											}
											tableBody={
												<MobileDetailsBody
													type="Return"
													flightObj={bigFlightData.selectedArrival}
												/>
											}
										/>
									)}
								</>
							)}
						</div>
						<div className="details-desktop">
							{isMultiCityAndLocal ? (
								<>
									{bigFlightData.selectedMultiCityFlights?.map(
										(flightObj: any, ind: number) => (
											<PlainTable
												tableHead={
													<DetailsTableHead
														type="Depart"
														flightObj={flightObj}
														multi={true}
														index={ind + 1}
													/>
												}
												tableBody={
													<DetailsTableBody
														type="Depart"
														flightObj={flightObj}
													/>
												}
												key={ind}
											/>
										)
									)}
								</>
							) : isMulticityAndIntl ? (
								bigFlightData.selectedDeparture.outbound.map(
									(selectedItem: any, ind: number) => (
										<PlainTable
											tableHead={
												<DetailsTableHead
													type="Depart"
													flightObj={{
														...bigFlightData.selectedDeparture,
														outbound:
															bigFlightData.selectedDeparture?.outbound[ind],
														outbound_stops:
															bigFlightData.selectedDeparture?.outbound_stops[
																ind
															],
														total_duration:
															bigFlightData.selectedDeparture?.total_duration[
																ind
															],
														total_outbound_duration:
															bigFlightData.selectedDeparture
																?.total_outbound_duration[ind],
													}}
													multi={true}
													index={ind + 1}
												/>
											}
											tableBody={
												<DetailsTableBody
													type="Depart"
													flightObj={{
														...bigFlightData.selectedDeparture,
														outbound:
															bigFlightData.selectedDeparture?.outbound[ind],
														outbound_stops:
															bigFlightData.selectedDeparture?.outbound_stops[
																ind
															],
														total_duration:
															bigFlightData.selectedDeparture?.total_duration[
																ind
															],
														total_outbound_duration:
															bigFlightData.selectedDeparture
																?.total_outbound_duration[ind],
													}}
												/>
											}
										/>
									)
								)
							) : (
								<>
									<PlainTable
										tableHead={
											<DetailsTableHead
												type="Depart"
												flightObj={bigFlightData.selectedDeparture}
											/>
										}
										tableBody={
											<DetailsTableBody
												type="Depart"
												flightObj={bigFlightData.selectedDeparture}
											/>
										}
									/>
									{bigFlightData.showTripType === "Roundtrip" && (
										<PlainTable
											tableHead={
												<DetailsTableHead
													type="Return"
													flightObj={bigFlightData.selectedArrival}
												/>
											}
											tableBody={
												<DetailsTableBody
													type="Return"
													flightObj={bigFlightData.selectedArrival}
												/>
											}
										/>
									)}
								</>
							)}
						</div>
					</div>
				</div>
				<MUIModal open={openPayment} handleClose={() => setOpenPayment(false)}>
					<PaymentMethodModal
						confirmBooking={confirmBooking}
						loading={bookingLoading}
						handleClose={() => setOpenPayment(false)}
						setReference={setReference}
						reference={reference}
						amount={amount}
					/>
				</MUIModal>
				<MUIModal open={openShare} handleClose={() => setOpenShare(false)}>
					<ShareTripDetailsModal handleClose={() => setOpenShare(false)} />
				</MUIModal>
				<MUIModal
					open={openAppendPassengers}
					handleClose={() => setOpenAppendPassengers(false)}
				>
					<SelectTravellerModal
						setShowTraveller={setOpenAppendPassengers}
						setAddNewTraveller={() => {}}
						tempSelected={tempSelected}
						setTempSelected={setTempSelected}
						handleClose={() => setOpenAppendPassengers(false)}
						handleSelectedTravellers={updatePassengerList}
						showCreate={false}
					/>
				</MUIModal>
				<MUIModal
					open={restartSearchLoading}
					handleClose={() => setRestartSearchLoading(false)}
					cantClose={true}
				>
					<LoadingSuccessModal
						successMessage="Passenger update successful"
						successFunc={() => {}}
						handleClose={() => setRestartSearchLoading(false)}
					/>
				</MUIModal>
				<LoadingModalsDebacle
					successLoading={successLoading}
					setSuccessLoading={setSuccessLoading}
					loading={bookingLoading}
					handleClose={() => {}}
					loadingMessage={
						paymentMethod === "book_on_hold"
							? "Hold on, we are confirming details of your flight"
							: "Hold on, we are confirming your balance"
					}
					successMessage={
						paymentMethod === "book_on_hold"
							? "Booked successfully"
							: "Payment successful"
					}
					showSuccess={true}
					successFunc={successFunc}
				/>
			</>
		);
};

export default FlightConfirmationPage;

// tableBody Components
const TravelIteneraryBody = (prop: any) => {
	const { props } = prop;
	console.log(props);

	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	// const updateBigFlightData = useTripStore(
	// 	(state: any) => state.updateBigFlightData
	// );
	// const localAirports = useTripStore((state: any) => state.localAirports);
	const globalAirports = useTripStore((state: any) => state.globalAirports);

	console.log("bigFlightData", bigFlightData);

	return (
		<>
			<div className="tableBody travelIte">
				<div className="travelIte__location">
					<p className="big-text">
						{bigFlightData.showTripType === "Multicity"
							? `${
									globalAirports.filter(
										(item: any) =>
											item.iata_code === bigFlightData?.multiCity[0]?.departure
									)[0]?.city
							  } (${bigFlightData?.multiCity[0]?.departure}
									)`
							: `${bigFlightData?.departureCity[0]?.city} (${bigFlightData.departure}
							)`}
					</p>
					<TbArrowsRightLeft size={20} color="#9C9393" />
					<p className="big-text">
						{bigFlightData.showTripType === "Multicity"
							? `${
									globalAirports.filter(
										(item: any) =>
											item.iata_code ===
											bigFlightData?.multiCity[
												bigFlightData?.multiCity?.length - 1
											]?.destination
									)[0]?.city
							  } (${
									bigFlightData?.multiCity[bigFlightData?.multiCity?.length - 1]
										?.destination
							  }
									)`
							: `${bigFlightData?.arrivalCity[0]?.city} (${bigFlightData.destination}
							)`}
					</p>
				</div>
				<div className="travelIte__info">
					<span>
						<span>
							{DateTime.fromISO(
								bigFlightData.showTripType === "Multicity"
									? bigFlightData.multiCity[0].departure_date
									: bigFlightData.departure_date
							).toLocaleString({
								weekday: "short",
								day: "2-digit",
								month: "short",
							})}
						</span>{" "}
						{bigFlightData.return_date ? (
							<>
								-{" "}
								<span>
									{DateTime.fromISO(bigFlightData.return_date).toLocaleString({
										weekday: "short",
										day: "2-digit",
										month: "short",
									})}
								</span>
							</>
						) : (
							<></>
						)}
					</span>
					<span>•</span>
					<span>{bigFlightData.selectedTraveller?.length + " passengers"}</span>
					<span>•</span>
					<span>
						{bigFlightData.cabin?.charAt(0).toUpperCase() +
							bigFlightData.cabin?.slice(1)}
					</span>
				</div>
			</div>
		</>
	);
};

const PriceBreakdownBody = (prop: any) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	// const updateBigFlightData = useTripStore(
	// 	(state: any) => state.updateBigFlightData
	// );
	let { props } = prop;
	console.log(props);

	const isRoundTripAndIntl =
		bigFlightData?.showTripType === "Roundtrip" &&
		bigFlightData?.route === "international";
	const isMultiCityAndLocal =
		bigFlightData?.showTripType === "Multicity" &&
		bigFlightData?.route === "domestic";
	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	let fareCost =
		bigFlightData?.selectedDeparture?.amount +
		(bigFlightData?.selectedArrival === undefined
			? 0
			: bigFlightData?.selectedArrival?.amount);
	let tax =
		bigFlightData?.selectedDeparture?.tax === undefined
			? 0
			: bigFlightData?.selectedDeparture?.tax +
			  (bigFlightData?.selectedArrival === undefined
					? 0
					: bigFlightData?.selectedArrival?.tax);
	let bookingFee = 0;

	if (isRoundTripAndIntl || isMulticityAndIntl) {
		fareCost = bigFlightData.selectedDeparture?.amount;
		tax = bigFlightData.selectedDeparture.tax;
	}
	if (isMultiCityAndLocal) {
		fareCost = bigFlightData.selectedMultiCityFlights.reduce(
			(total: number, selectedObj: any) => total + selectedObj?.amount,
			0
		);
		tax = 0;
	}

	// const amount = fareCost + tax;

	console.log("fareCost", bigFlightData.selectedMultiCityFlights);

	return (
		<div className="tableBody">
			<div className="trip-col">
				<p>Adult {bigFlightData.selectedTraveller?.length}x</p>
				<span>:</span>
				<p>₦ {fareCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</p>
			</div>
			<div className="trip-col">
				<p>Taxes and Fees</p>
				<span>:</span>
				<p>₦ {tax.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</p>
			</div>
			<div className="trip-col">
				<p>Booking fee</p>
				<span>:</span>
				<p>₦ {bookingFee}</p>
			</div>
			<div className="trip-col total">
				<p>Grand Total</p>
				<span>:</span>
				<p>
					₦{" "}
					{(fareCost + tax + bookingFee)
						.toFixed(2)
						.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				</p>
			</div>
		</div>
	);
};

const DetailsTableHead = ({
	type = "Depart",
	flightObj,
	multi = false,
	index = 0,
}: {
	type: string;
	flightObj: any;
	multi?: boolean;
	index?: number;
}) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const globalAirports = useTripStore((state: any) => state.globalAirports);

	const [openDetails, setOpenDetails] = useState(false);
	const [modalType, setModalType] = useState<"departure" | "arrival">(
		"departure"
	);

	return (
		<div className="tableHead">
			<div className="col">
				<div>
					<p className="depart">{multi ? `Flight ${index}` : type}</p>
					{multi ? (
						<>
							{
								globalAirports.filter(
									(item: any) =>
										item.iata_code === flightObj?.outbound[0]?.airport_from
								)[0]?.city
							}{" "}
							({flightObj?.outbound[0]?.airport_from}) -{" "}
							{
								globalAirports.filter(
									(item: any) =>
										item.iata_code ===
										flightObj?.outbound[flightObj?.outbound?.length - 1]
											?.airport_to
								)[0]?.city
							}{" "}
							({flightObj?.outbound[0]?.airport_to})
						</>
					) : (
						<>
							{type === "Depart" ? (
								<p>
									{bigFlightData.departureCity[0]?.city} (
									{bigFlightData.departure}) -{" "}
									{bigFlightData.arrivalCity[0]?.city} (
									{bigFlightData.destination})
								</p>
							) : (
								<p>
									{bigFlightData.arrivalCity[0]?.city} (
									{bigFlightData.destination}) -{" "}
									{bigFlightData.departureCity[0]?.city} (
									{bigFlightData.departure})
								</p>
							)}
						</>
					)}
				</div>
				<p
					className="details"
					onClick={() => {
						setModalType(type === "Depart" ? "departure" : "arrival");
						setOpenDetails(true);
					}}
				>
					Details
				</p>
			</div>
			<MUIModal open={openDetails} handleClose={() => setOpenDetails(false)}>
				<FlightInfoModal type={modalType} singleFlight={flightObj} />
			</MUIModal>
		</div>
	);
};

const DetailsTableBody = ({
	type = "Depart",
	flightObj,
}: {
	type: string;
	flightObj: any;
}) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);

	console.log("flightObj", flightObj);

	const isRoundTripAndIntl =
		bigFlightData?.showTripType === "Roundtrip" &&
		bigFlightData?.route === "international";

	return (
		<div className="tableBody detailsTable">
			<div className="block-mobile">
				<div className="double-col-mobile">
					<img
						src={`https://image.tiqwa.com/airlines/${
							(type === "Return" && isRoundTripAndIntl
								? flightObj.outbound_return
								: flightObj.outbound)[0]?.airline_details?.code
						}.png`}
						alt=""
					/>
					<div>
						<p>
							{
								(type === "Return" && isRoundTripAndIntl
									? flightObj.outbound_return
									: flightObj.outbound)[0]?.airline_details?.name
							}
						</p>
						<p>
							{capitalizeFirstLetter(
								(type === "Return" && isRoundTripAndIntl
									? flightObj.outbound_return
									: flightObj.outbound)[0].cabin_type
							)}
						</p>
					</div>
				</div>
			</div>
			<div className="block-mobile">
				<div className="double-col-mobile">
					<DepartureIcon />
					<div>
						<p>
							{type === "Depart"
								? bigFlightData.departureCity[0]?.city
								: bigFlightData.arrivalCity[0]?.city}{" "}
							{
								(type === "Return" && isRoundTripAndIntl
									? flightObj.outbound_return
									: flightObj.outbound)[0].airport_from
							}
						</p>
						<p>
							{DateTime.fromISO(
								(type === "Return" && isRoundTripAndIntl
									? flightObj.outbound_return
									: flightObj.outbound)[0].departure_time
							).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
							<span className="gray" style={{ marginLeft: "0.5rem" }}>
								{DateTime.fromISO(
									(type === "Return" && isRoundTripAndIntl
										? flightObj.outbound_return
										: flightObj.outbound)[0].departure_time
								).toLocaleString({
									weekday: "short",
									day: "2-digit",
									month: "short",
								})}
							</span>
						</p>
					</div>
				</div>
			</div>
			<div className="block-mobile">
				<div className="double-col-mobile">
					<ArrivalIcon />
					<div>
						<p>
							{type === "Depart"
								? bigFlightData.arrivalCity[0]?.city
								: bigFlightData.departureCity[0]?.city}{" "}
							{
								(type === "Return" && isRoundTripAndIntl
									? flightObj.outbound_return
									: flightObj.outbound)[
									(type === "Return" && isRoundTripAndIntl
										? flightObj.outbound_return
										: flightObj.outbound
									)?.length - 1
								].airport_to
							}
						</p>
						<p>
							{DateTime.fromISO(
								(type === "Return" && isRoundTripAndIntl
									? flightObj.outbound_return
									: flightObj.outbound)[
									(type === "Return" && isRoundTripAndIntl
										? flightObj.outbound_return
										: flightObj.outbound
									)?.length - 1
								].arrival_time
							).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
							<span className="gray" style={{ marginLeft: "0.5rem" }}>
								{DateTime.fromISO(
									(type === "Return" && isRoundTripAndIntl
										? flightObj.outbound_return
										: flightObj.outbound)[
										(type === "Return" && isRoundTripAndIntl
											? flightObj.outbound_return
											: flightObj.outbound
										)?.length - 1
									].arrival_time
								).toLocaleString({
									weekday: "short",
									day: "2-digit",
									month: "short",
								})}
							</span>
						</p>
					</div>
				</div>
			</div>
			<div className="block-mobile">
				<div className="double-col-mobile">
					<BaggageIcon color="rgba(0, 0, 0, 0.44)" />
					<p className="big-text gray">
						{
							(type === "Return" && isRoundTripAndIntl
								? flightObj.outbound_return
								: flightObj.outbound)[0].baggage
						}
					</p>
				</div>
			</div>
		</div>
	);
};

const FlightCategorization = () => {
	const [tagOptions, setTagOptions] = useState<string[]>([
		"Client visitation",
		"Business call",
		"Sales",
		"Visitation",
	]);
	const [selectedTags, setSelectedTags] = useState<string[]>([]);

	return (
		<div className="flightCategorisation">
			<h4>Categorise this trip</h4>
			<div className="flightCategorisation__box">
				<div className="group">
					<p className="group__title">Group</p>
					<DropDown
						label="Select or add group"
						options={tagOptions}
						setOptions={setTagOptions}
						selectedTags={selectedTags}
						setSelectedTags={setSelectedTags}
					/>
				</div>
				<div className="tags">
					{selectedTags.map((item) => (
						<CategoryTag
							label={item}
							deleteFunc={() => {
								setSelectedTags((prev) => prev.filter((tag) => tag !== item));
							}}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

// create a dropdown component
const DropDown = ({
	label,
	options,
	setOptions,
	selectedTags,
	setSelectedTags,
}: {
	label: string;
	options: string[];
	setOptions: Dispatch<SetStateAction<string[]>>;
	selectedTags: string[];
	setSelectedTags: Dispatch<SetStateAction<string[]>>;
}) => {
	const [open, setOpen] = useState(false);
	const [selected] = useState("");
	const [openGroupModal, setOpenGroupModal] = useState(false);

	const wrapperRef = useRef<HTMLDivElement>(null);

	useOutsideAlerter(wrapperRef, () => {
		setOpen(false);
	});

	return (
		<>
			<div className="dropDown" ref={wrapperRef}>
				<div
					className={`dropDown__selected dropDown__selected--${
						!selected && "label"
					}`}
					onClick={() => setOpen((prev) => !prev)}
				>
					<p>{selected ? selected : label}</p>
					<IoChevronDown size={12} />
				</div>

				<div
					className={`dropDown__content dropDown__content--${open && "show"}`}
				>
					<p className="dropDown__content__option dropDown__content__option--noHover">
						Select or add group
					</p>
					<div className="dropDown__content__optionList">
						{options.map((item) => (
							<p
								onClick={() => {
									if (!selectedTags.includes(item)) {
										setSelectedTags((prev) => [...prev, item]);
									} else {
										setSelectedTags((prev) =>
											prev.filter((tag) => tag !== item)
										);
									}

									setOpen(false);
								}}
								className={`dropDown__content__option dropDown__content__option--${
									selectedTags.includes(item) && "selected"
								}`}
							>
								{item}
								<FiCheck size={16} />
							</p>
						))}
					</div>
					<div className="dropDown__content__btnWrap">
						<button onClick={() => setOpenGroupModal(true)}>
							<IoAddOutline />
							Add new group
						</button>
					</div>
				</div>
			</div>
			<MUIModal
				open={openGroupModal}
				handleClose={() => setOpenGroupModal(false)}
			>
				<NewGroupModal
					handleClose={() => setOpenGroupModal(false)}
					addFunc={(val: string) => {
						if (!options.includes(val)) {
							setOptions((prev: string[]) => [...prev, val]);
							if (!selectedTags.includes(val)) {
								setSelectedTags((prev: string[]) => [...prev, val]);
							} else {
								toast.error("Group already selected");
							}
						} else {
							toast.error("Group already exists");
						}
					}}
				/>
			</MUIModal>
		</>
	);
};

// mobile tables

const MobileDetailsHead = ({
	type = "Depart",
	flightObj,
	multi = false,
	index = 0,
}: {
	type: string;
	flightObj: any;
	multi?: boolean;
	index?: number;
}) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);

	return (
		<div className="tableHead">
			<div className="col">
				<div>
					<p className="depart">{multi ? `Flight ${index}` : type}</p>
				</div>
				{type === "Depart" ? (
					<p>
						{bigFlightData.departureCity[0]?.city} ({bigFlightData.departure}) -{" "}
						{bigFlightData.arrivalCity[0]?.city} ({bigFlightData.destination})
					</p>
				) : (
					<p>
						{bigFlightData.arrivalCity[0]?.city} ({bigFlightData.destination}) -{" "}
						{bigFlightData.departureCity[0]?.city} ({bigFlightData.departure})
					</p>
				)}
			</div>
		</div>
	);
};

const MobileDetailsBody = ({
	type = "Depart",
	flightObj,
}: {
	type: string;
	flightObj: any;
}) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);

	console.log("flightObj", flightObj);

	const isRoundTripAndIntl =
		bigFlightData?.showTripType === "Roundtrip" &&
		bigFlightData?.route === "international";

	return (
		<div className="tableBody detailsTable">
			<div className="block-mobile">
				<div className="double-col-mobile">
					<img
						src={`https://image.tiqwa.com/airlines/${
							(type === "Return" && isRoundTripAndIntl
								? flightObj.outbound_return
								: flightObj.outbound)[0]?.airline_details?.code
						}.png`}
						alt=""
					/>
					<div>
						<p>
							{
								(type === "Return" && isRoundTripAndIntl
									? flightObj.outbound_return
									: flightObj.outbound)[0]?.airline_details?.name
							}
						</p>
						<p>
							{capitalizeFirstLetter(
								(type === "Return" && isRoundTripAndIntl
									? flightObj.outbound_return
									: flightObj.outbound)[0].cabin_type
							)}
						</p>
					</div>
				</div>
			</div>
			<div className="block-mobile">
				<div className="double-col-mobile">
					<DepartureIcon />
					<div>
						<p>
							{type === "Depart"
								? bigFlightData.departureCity[0]?.city
								: bigFlightData.arrivalCity[0]?.city}{" "}
							{
								(type === "Return" && isRoundTripAndIntl
									? flightObj.outbound_return
									: flightObj.outbound)[0].airport_from
							}
						</p>
						<p>
							{DateTime.fromISO(
								(type === "Return" && isRoundTripAndIntl
									? flightObj.outbound_return
									: flightObj.outbound)[0].departure_time
							).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
							<span className="gray" style={{ marginLeft: "0.5rem" }}>
								{DateTime.fromISO(
									(type === "Return" && isRoundTripAndIntl
										? flightObj.outbound_return
										: flightObj.outbound)[0].departure_time
								).toLocaleString({
									weekday: "short",
									day: "2-digit",
									month: "short",
								})}
							</span>
						</p>
					</div>
				</div>
			</div>
			<div className="block-mobile">
				<div className="double-col-mobile">
					<ArrivalIcon />
					<div>
						<p>
							{type === "Depart"
								? bigFlightData.arrivalCity[0]?.city
								: bigFlightData.departureCity[0]?.city}{" "}
							{
								(type === "Return" && isRoundTripAndIntl
									? flightObj.outbound_return
									: flightObj.outbound)[
									(type === "Return" && isRoundTripAndIntl
										? flightObj.outbound_return
										: flightObj.outbound
									)?.length - 1
								].airport_to
							}
						</p>
						<p>
							{DateTime.fromISO(
								(type === "Return" && isRoundTripAndIntl
									? flightObj.outbound_return
									: flightObj.outbound)[
									(type === "Return" && isRoundTripAndIntl
										? flightObj.outbound_return
										: flightObj.outbound
									)?.length - 1
								].arrival_time
							).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
							<span className="gray" style={{ marginLeft: "0.5rem" }}>
								{DateTime.fromISO(
									(type === "Return" && isRoundTripAndIntl
										? flightObj.outbound_return
										: flightObj.outbound)[
										(type === "Return" && isRoundTripAndIntl
											? flightObj.outbound_return
											: flightObj.outbound
										)?.length - 1
									].arrival_time
								).toLocaleString({
									weekday: "short",
									day: "2-digit",
									month: "short",
								})}
							</span>
						</p>
					</div>
				</div>
			</div>
			<div className="block-mobile">
				<div className="double-col-mobile">
					<BaggageIcon color="rgba(0, 0, 0, 0.44)" />
					<p className="big-text gray">
						{
							(type === "Return" && isRoundTripAndIntl
								? flightObj.outbound_return
								: flightObj.outbound)[0].baggage
						}
					</p>
				</div>
			</div>
		</div>
	);
};
